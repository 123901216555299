import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _Filter;
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { translateModKey } from "discourse/lib/utilities";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let Filter = (_class = (_Filter = class Filter extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "sidebarState", _descriptor, this);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.sidebarState.clearFilter();
  }
  get shouldDisplay() {
    return this.sidebarState.currentPanel.filterable;
  }
  get displayClearFilter() {
    return this.sidebarState.filter.length > 0;
  }
  setFilter(event1) {
    this.sidebarState.filter = event1.target.value.toLowerCase();
  }
  handleEscape(event1) {
    if (event1.key === "Escape") {
      event1.stopPropagation();
      if (this.sidebarState.filter.length > 0) {
        this.sidebarState.filter = "";
      } else {
        event1.target.blur();
      }
    }
  }
  clearFilter() {
    this.sidebarState.clearFilter();
    document.querySelector(".sidebar-filter__input").focus();
  }
  get showShortcutCombo() {
    return !this.displayClearFilter;
  }
  get sidebarShortcutCombo() {
    return `${translateModKey("Meta")}+/`;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.shouldDisplay}}
      <div class="sidebar-filter">
        <div class="sidebar-filter__input-container">
          <input
            {{on "input" this.setFilter}}
            {{on "keydown" this.handleEscape}}
            value={{this.sidebarState.filter}}
            placeholder={{i18n "sidebar.filter"}}
            type="text"
            class="sidebar-filter__input"
          />
          {{#if this.showShortcutCombo}}
            <span
              class="sidebar-filter__shortcut-hint"
            >{{this.sidebarShortcutCombo}}</span>
          {{/if}}

          {{#if this.displayClearFilter}}
            <DButton
              @action={{this.clearFilter}}
              @icon="times"
              class="sidebar-filter__clear"
            />
          {{/if}}
        </div>
      </div>
    {{/if}}
  
*/
{
  "id": "G8g82BAT",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldDisplay\"]],[[[1,\"      \"],[10,0],[14,0,\"sidebar-filter\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"sidebar-filter__input-container\"],[12],[1,\"\\n          \"],[11,\"input\"],[16,2,[30,0,[\"sidebarState\",\"filter\"]]],[16,\"placeholder\",[28,[32,0],[\"sidebar.filter\"],null]],[24,0,\"sidebar-filter__input\"],[24,4,\"text\"],[4,[32,1],[\"input\",[30,0,[\"setFilter\"]]],null],[4,[32,1],[\"keydown\",[30,0,[\"handleEscape\"]]],null],[12],[13],[1,\"\\n\"],[41,[30,0,[\"showShortcutCombo\"]],[[[1,\"            \"],[10,1],[14,0,\"sidebar-filter__shortcut-hint\"],[12],[1,[30,0,[\"sidebarShortcutCombo\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"displayClearFilter\"]],[[[1,\"            \"],[8,[32,2],[[24,0,\"sidebar-filter__clear\"]],[[\"@action\",\"@icon\"],[[30,0,[\"clearFilter\"]],\"times\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/filter.js",
  "scope": () => [i18n, on, DButton],
  "isStrictMode": true
}), _Filter), _Filter), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "setFilter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEscape", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleEscape"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype)), _class);
export { Filter as default };