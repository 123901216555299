import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
import { tracked } from "@glimmer/tracking";
import { getOwner, setOwner } from "@ember/application";
import { service } from "@ember/service";
import { TrackedArray } from "@ember-compat/tracked-built-ins";
import { NotificationLevels } from "discourse/lib/notification-levels";
import Topic from "discourse/models/topic";
import { bind } from "discourse-common/utils/decorators";
let BulkSelectHelper = (_class = class BulkSelectHelper {
  constructor(context) {
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "modal", _descriptor2, this);
    _initializerDefineProperty(this, "pmTopicTrackingState", _descriptor3, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor4, this);
    _initializerDefineProperty(this, "bulkSelectEnabled", _descriptor5, this);
    _initializerDefineProperty(this, "autoAddTopicsToBulkSelect", _descriptor6, this);
    _initializerDefineProperty(this, "autoAddBookmarksToBulkSelect", _descriptor7, this);
    _defineProperty(this, "selected", new TrackedArray());
    setOwner(this, getOwner(context));
  }
  clear() {
    this.selected.length = 0;
  }
  addTopics(topics) {
    this.selected.concat(topics);
  }
  get selectedCategoryIds() {
    return this.selected.mapBy("category_id").uniq();
  }
  toggleBulkSelect() {
    this.bulkSelectEnabled = !this.bulkSelectEnabled;
    this.clear();
  }
  dismissRead(operationType, options) {
    const operation = operationType === "posts" ? {
      type: "dismiss_posts"
    } : {
      type: "change_notification_level",
      notification_level_id: NotificationLevels.REGULAR
    };
    const isTracked = (this.router.currentRoute.queryParams["f"] || this.router.currentRoute.queryParams["filter"]) === "tracked";
    const promise = this.selected.length ? Topic.bulkOperation(this.selected, operation, {}, isTracked) : Topic.bulkOperationByFilter("unread", operation, options, isTracked);
    promise.then(result => {
      if (result?.topic_ids) {
        if (options?.private_message_inbox) {
          this.pmTopicTrackingState.removeTopics(result.topic_ids);
        } else {
          this.topicTrackingState.removeTopics(result.topic_ids);
        }
      }
      this.modal.close();
      this.router.refresh();
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pmTopicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bulkSelectEnabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "autoAddTopicsToBulkSelect", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "autoAddBookmarksToBulkSelect", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "toggleBulkSelect", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkSelect"), _class.prototype)), _class);
export { BulkSelectHelper as default };