import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _class2, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './reorder-categories.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./reorder-categories.hbs";
import { setComponentTemplate } from "@ember/component";
let Entry = (_class = class Entry {
  constructor(_ref) {
    let {
      position,
      depth,
      category,
      descendantCount
    } = _ref;
    _initializerDefineProperty(this, "position", _descriptor, this);
    this.position = position;
    this.depth = depth;
    this.category = category;
    this.descendantCount = descendantCount;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "position", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
let ReorderCategories = (_class2 = class ReorderCategories extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor2, this);
    _initializerDefineProperty(this, "changed", _descriptor3, this);
    _initializerDefineProperty(this, "entries", _descriptor4, this);
    _initializerDefineProperty(this, "highlightedCategoryId", _descriptor5, this);
  }
  get sortedEntries() {
    return this.entries.sortBy("position");
  }
  reorder(from) {
    from ??= this.site.categories.map(category => ({
      category,
      position: category.position
    }));
    return this.createEntries([...from.sortBy("position")]);
  }

  /**
   * 1. Make sure all categories have unique position numbers.
   * 2. Place sub-categories after their parent categories while maintaining
   *    the same relative order.
   *
   *    e.g.
   *      parent/c2/c1          parent
   *      parent/c1             parent/c1
   *      parent          =>    parent/c2
   *      other                 parent/c2/c1
   *      parent/c2             other
   **/
  createEntries(from) {
    let position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    let categoryId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    let depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    let result = [];
    for (const entry of from) {
      if (categoryId === null && !entry.category.parent_category_id || entry.category.parent_category_id === categoryId) {
        const descendants = this.createEntries(from, position + result.length + 1, entry.category.id, depth + 1);
        result = [...result, new Entry({
          position: position + result.length,
          depth,
          category: entry.category,
          descendantCount: descendants.length
        }), ...descendants];
      }
    }
    return result;
  }
  move(entry, delta) {
    let targetPosition = entry.position + delta;

    // Adjust target position for sub-categories
    if (delta > 0) {
      // Moving down (position gets larger)
      if (entry.descendantCount) {
        // This category has subcategories, adjust targetPosition to account for them
        if (entry.descendantCount >= delta) {
          // Only apply offset if target position is occupied by a subcategory
          // Seems weird but fixes a UX quirk
          targetPosition += entry.descendantCount;
        }
      }
    } else {
      // Moving up (position gets smaller)
      const ancestors = this.sortedEntries[targetPosition]?.category?.ancestors;
      if (ancestors) {
        // Target category is a subcategory, adjust targetPosition to account for ancestors
        const highestAncestorEntry = this.sortedEntries.findBy("category.id", ancestors[0].id);
        targetPosition = highestAncestorEntry.position;
      }
    }

    // Adjust target position for range bounds
    if (targetPosition >= this.entries.length) {
      // Set to max
      targetPosition = this.entries.length - 1;
    } else if (targetPosition < 0) {
      // Set to min
      targetPosition = 0;
    }

    // Update other categories between current and target position
    for (const e of this.sortedEntries) {
      if (delta > 0) {
        // Moving down (position gets larger)
        if (e.position > entry.position && e.position <= targetPosition) {
          e.position -= 1;
        }
      } else {
        // Moving up (position gets smaller)
        if (e.position < entry.position && e.position >= targetPosition) {
          e.position += 1;
        }
      }
    }

    // Update this category's position to target position
    entry.position = targetPosition;
    this.entries = this.reorder(this.sortedEntries);
    this.changed = true;
    this.toggleHighlight(entry.category.id);
  }
  toggleHighlight(categoryId) {
    this.highlightedCategoryId = categoryId;
    setTimeout(() => {
      if (this.highlightedCategoryId === categoryId) {
        this.highlightedCategoryId = null;
      }
    }, 3000);
  }
  change(entry, newPosition) {
    const delta = parseInt(newPosition, 10) - entry.position;
    this.move(entry, delta);
  }
  async save() {
    const entries = this.reorder(this.sortedEntries);
    const data = {};
    for (const {
      category,
      position
    } of entries) {
      data[category.id] = position;
    }
    try {
      await ajax("/categories/reorder", {
        type: "POST",
        data: {
          mapping: JSON.stringify(data)
        }
      });
      window.location.reload();
    } catch (e) {
      popupAjaxError(e);
    }
  }
}, (_descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "changed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "entries", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.reorder();
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "highlightedCategoryId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "move", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "move"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleHighlight", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleHighlight"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "change", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "change"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "save", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2);
export { ReorderCategories as default };
setComponentTemplate(TEMPLATE, ReorderCategories);