import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './choose-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty, isPresent } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { searchForTerm } from "discourse/lib/search";
import { INPUT_DELAY } from "discourse-common/config/environment";
import { debounce } from "discourse-common/utils/decorators";

// args:
// topicChangedCallback
//
// optional:
// currentTopicId
// additionalFilters
// label
// loadOnInit
import TEMPLATE from "./choose-topic.hbs";
import { setComponentTemplate } from "@ember/component";
let ChooseTopic = (_dec = debounce(INPUT_DELAY), (_class = class ChooseTopic extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loading", _descriptor, this);
    _initializerDefineProperty(this, "topics", _descriptor2, this);
    _defineProperty(this, "topicTitle", void 0);
    if (this.args.loadOnInit && isPresent(this.args.additionalFilters)) {
      this.initialSearch();
    }
  }
  async initialSearch() {
    try {
      const results = await searchForTerm(this.args.additionalFilters);
      if (!results?.posts?.length) {
        return;
      }
      this.topics = results.posts.mapBy("topic").filter(t => t.id !== this.args.currentTopicId);
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.loading = false;
    }
  }
  async search(title) {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    if (isEmpty(title) && isEmpty(this.args.additionalFilters)) {
      this.topics = null;
      this.loading = false;
      return;
    }
    const titleWithFilters = [title, this.args.additionalFilters].filter(Boolean).join(" ");
    let searchParams;
    if (isPresent(title)) {
      searchParams = {
        typeFilter: "topic",
        restrictToArchetype: "regular",
        searchForId: true
      };
    }
    try {
      const results = await searchForTerm(titleWithFilters, searchParams);

      // search term changed after the request was fired but before we
      // got a response, ignore results.
      if (title !== this.topicTitle) {
        return;
      }
      if (!results?.posts?.length) {
        this.topics = null;
        return;
      }
      this.topics = results.posts.mapBy("topic").filter(t => t.id !== this.args.currentTopicId);
      if (this.topics.length === 1) {
        this.chooseTopic(this.topics[0]);
      }
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.loading = false;
    }
  }
  async onTopicTitleChange(event) {
    this.topicTitle = event.target.value;
    this.loading = true;
    await this.search(this.topicTitle);
  }
  ignoreEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  chooseTopic(topic) {
    this.args.topicChangedCallback(topic);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topics", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "search", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "search"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTopicTitleChange", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onTopicTitleChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ignoreEnter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "ignoreEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "chooseTopic", [action], Object.getOwnPropertyDescriptor(_class.prototype, "chooseTopic"), _class.prototype)), _class));
export { ChooseTopic as default };
setComponentTemplate(TEMPLATE, ChooseTopic);