import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
import { registerDestructor } from "@ember/destroyable";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import Modifier from "ember-modifier";
import { addWidgetCleanCallback, removeWidgetCleanCallback } from "discourse/components/mount-widget";
import { headerOffset } from "discourse/lib/offset-calculator";
import { bind } from "discourse-common/utils/decorators";
const STICKY_CLASS = "sticky-avatar";
const TOPIC_POST_SELECTOR = ".post-stream .topic-post";
let StickyAvatars = (_class = class StickyAvatars extends Modifier {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "appEvents", _descriptor2, this);
    _defineProperty(this, "element", void 0);
    _defineProperty(this, "intersectionObserver", void 0);
    registerDestructor(this, instance => instance.cleanup());
  }
  modify(element) {
    if (this.site.mobileView || !("IntersectionObserver" in window)) {
      return;
    }
    this.element = element;
    this.appEvents.on("topic:current-post-scrolled", this, this._handlePostNodes);
    this.appEvents.on("topic:scrolled", this, this._handleScroll);
    this.appEvents.on("page:topic-loaded", this, this._initIntersectionObserver);
    addWidgetCleanCallback("post-stream", this._clearIntersectionObserver);
  }
  cleanup() {
    if (this.site.mobileView || !("IntersectionObserver" in window)) {
      return;
    }
    this.appEvents.off("topic:current-post-scrolled", this, this._handlePostNodes);
    this.appEvents.off("topic:scrolled", this, this._handleScroll);
    this.appEvents.off("page:topic-loaded", this, this._initIntersectionObserver);
    removeWidgetCleanCallback("post-stream", this._clearIntersectionObserver);
  }
  _handleScroll(offset) {
    if (offset <= 0) {
      this.element.querySelectorAll(`${TOPIC_POST_SELECTOR}.${STICKY_CLASS}`).forEach(node => node.classList.remove(STICKY_CLASS));
    }
  }
  _handlePostNodes() {
    this._clearIntersectionObserver();
    this._initIntersectionObserver();
    schedule("afterRender", () => {
      this.element.querySelectorAll(TOPIC_POST_SELECTOR).forEach(postNode => {
        this.intersectionObserver.observe(postNode);
        const topicAvatarNode = postNode.querySelector(".topic-avatar");
        if (!topicAvatarNode || !postNode.querySelector("#post_1")) {
          return;
        }
        const topicMapNode = postNode.querySelector(".topic-map");
        if (!topicMapNode) {
          return;
        }
        topicAvatarNode.style.marginBottom = `${topicMapNode.clientHeight}px`;
      });
    });
  }
  _initIntersectionObserver() {
    schedule("afterRender", () => {
      const offset = headerOffset();
      const headerOffsetInPx = offset <= 0 ? "0px" : `-${offset}px`;
      this.intersectionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (!entry.isIntersecting || entry.intersectionRatio === 1) {
            entry.target.classList.remove(STICKY_CLASS);
            return;
          }
          entry.target.classList.add(STICKY_CLASS);
        });
      }, {
        threshold: [0.0, 1.0],
        rootMargin: `${headerOffsetInPx} 0px 0px 0px`
      });
    });
  }
  _clearIntersectionObserver() {
    this.intersectionObserver?.disconnect();
    this.intersectionObserver = null;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "_handleScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_handleScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_handlePostNodes", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_handlePostNodes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_initIntersectionObserver", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_initIntersectionObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_clearIntersectionObserver", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "_clearIntersectionObserver"), _class.prototype)), _class);
export { StickyAvatars as default };