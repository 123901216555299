import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
/* import __COLOCATED_TEMPLATE__ from './flag.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { reload } from "discourse/helpers/page-reloader";
import { MAX_MESSAGE_LENGTH } from "discourse/models/post-action-type";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./flag.hbs";
import { setComponentTemplate } from "@ember/component";
const NOTIFY_MODERATORS_KEY = "notify_moderators";
let Flag = (_class = class Flag extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "adminTools", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    _initializerDefineProperty(this, "site", _descriptor4, this);
    _initializerDefineProperty(this, "appEvents", _descriptor5, this);
    _initializerDefineProperty(this, "userDetails", _descriptor6, this);
    _initializerDefineProperty(this, "selected", _descriptor7, this);
    _initializerDefineProperty(this, "message", _descriptor8, this);
    _initializerDefineProperty(this, "isConfirmed", _descriptor9, this);
    _initializerDefineProperty(this, "isWarning", _descriptor10, this);
    _initializerDefineProperty(this, "spammerDetails", _descriptor11, this);
    this.adminTools?.checkSpammer(this.args.model.flagModel.user_id).then(result => this.spammerDetails = result);
  }
  get flagActions() {
    return {
      icon: "gavel",
      label: I18n.t("flagging.take_action"),
      actions: [{
        id: "agree_and_hide",
        icon: "thumbs-up",
        label: I18n.t("flagging.take_action_options.default.title"),
        description: I18n.t("flagging.take_action_options.default.details")
      }, {
        id: "agree_and_suspend",
        icon: "ban",
        label: I18n.t("flagging.take_action_options.suspend.title"),
        description: I18n.t("flagging.take_action_options.suspend.details"),
        client_action: "suspend"
      }, {
        id: "agree_and_silence",
        icon: "microphone-slash",
        label: I18n.t("flagging.take_action_options.silence.title"),
        description: I18n.t("flagging.take_action_options.silence.details"),
        client_action: "silence"
      }]
    };
  }
  get canSendWarning() {
    return !this.args.model.flagTarget.targetsTopic() && this.currentUser.staff && this.selected?.name_key === "notify_user";
  }
  get showDeleteSpammer() {
    return this.spammerDetails?.canDelete && this.selected?.name_key === "spam";
  }
  get submitLabel() {
    if (this.selected?.is_custom_flag) {
      return this.args.model.flagTarget.customSubmitLabel();
    }
    return this.args.model.flagTarget.submitLabel();
  }
  get title() {
    return this.args.model.flagTarget.title();
  }
  get flagsAvailable() {
    return this.args.model.flagTarget.flagsAvailable(this).filterBy("enabled");
  }
  get staffFlagsAvailable() {
    return this.flagsAvailable.length > 1;
  }
  get submitEnabled() {
    if (!this.selected) {
      return false;
    }
    if (!this.selected.is_custom_flag) {
      return true;
    }
    if (this.selected.isIllegal && !this.isConfirmed) {
      return false;
    }
    const len = this.message?.length || 0;
    return len >= this.siteSettings.min_personal_message_post_length && len <= MAX_MESSAGE_LENGTH;
  }
  get notifyModeratorsFlag() {
    return this.flagsAvailable.find(f => f.id === NOTIFY_MODERATORS_KEY);
  }
  get canTakeAction() {
    return !this.args.model.flagTarget.targetsTopic() && !this.selected?.is_custom_flag && this.currentUser.staff;
  }
  onKeydown(event) {
    if (this.submitEnabled && event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
      this.createFlag();
      return false;
    }
  }
  async penalize(adminToolMethod, performAction) {
    if (!this.adminTools) {
      return;
    }
    const createdBy = await User.findByUsername(this.args.model.flagModel.username);
    const opts = {
      before: performAction
    };
    if (this.args.model.flagTarget.editable()) {
      opts.postId = this.args.model.flagModel.id;
      opts.postEdit = this.args.model.flagModel.cooked;
    }
    return this.adminTools[adminToolMethod](createdBy, opts);
  }
  async deleteSpammer() {
    if (this.spammerDetails) {
      await this.spammerDetails.deleteUser();
      reload();
    }
  }
  async takeAction(actionable) {
    if (actionable.client_action === "suspend") {
      await this.penalize("showSuspendModal", () => this.createFlag({
        takeAction: true,
        skipClose: true
      }));
    } else if (actionable.client_action === "silence") {
      await this.penalize("showSilenceModal", () => this.createFlag({
        takeAction: true,
        skipClose: true
      }));
    } else if (actionable.client_action) {
      // eslint-disable-next-line no-console
      console.error(`No handler for ${actionable.client_action} found`);
    } else {
      this.args.model.setHidden();
      this.createFlag({
        takeAction: true
      });
    }
  }
  createFlag() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (this.selected.is_custom_flag) {
      opts.message = this.message;
    }
    this.args.model.flagTarget.create(this, opts);
  }
  createFlagAsWarning() {
    this.createFlag({
      isWarning: true
    });
    this.args.model.setHidden();
  }
  flagForReview() {
    this.selected ||= this.notifyModeratorsFlag;
    this.createFlag({
      queue_for_review: true
    });
    this.args.model.setHidden();
  }
  changePostActionType(actionType) {
    this.selected = actionType;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "adminTools", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userDetails", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selected", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "message", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isConfirmed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isWarning", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "spammerDetails", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "penalize", [action], Object.getOwnPropertyDescriptor(_class.prototype, "penalize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteSpammer", [action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteSpammer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "takeAction", [action], Object.getOwnPropertyDescriptor(_class.prototype, "takeAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createFlag", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createFlag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createFlagAsWarning", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createFlagAsWarning"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "flagForReview", [action], Object.getOwnPropertyDescriptor(_class.prototype, "flagForReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePostActionType", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changePostActionType"), _class.prototype)), _class);
export { Flag as default };
setComponentTemplate(TEMPLATE, Flag);