import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _DeferredRender;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import runAfterFramePaint from "discourse/lib/after-frame-paint";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let DeferredRender = (_class = (_DeferredRender = class DeferredRender extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "shouldRender", _descriptor, this);
    runAfterFramePaint(() => this.shouldRender = true);
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.shouldRender}}
      {{yield}}
    {{/if}}
  
*/
{
  "id": "Ze/rFA8f",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[18,1,null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/deferred-render.js",
  "isStrictMode": true
}), _DeferredRender), _DeferredRender), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldRender", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
})), _class);
export { DeferredRender as default };