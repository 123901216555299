import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './bookmark-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import { service } from "@ember/service";
import { Promise } from "rsvp";
import BookmarkModal from "discourse/components/modal/bookmark";
import { ajax } from "discourse/lib/ajax";
import { BookmarkFormData } from "discourse/lib/bookmark-form-data";
import { openLinkInNewTab, shouldOpenInNewTab } from "discourse/lib/click-track";
import I18n from "discourse-i18n";
import TEMPLATE from "./bookmark-list.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  dialog: service(),
  modal: service(),
  classNames: ["bookmark-list-wrapper"],
  get canDoBulkActions() {
    return this.bulkSelectHelper?.selected.length;
  },
  get selected() {
    return this.bulkSelectHelper?.selected;
  },
  get selectedCount() {
    return this.selected?.length || 0;
  },
  removeBookmark(bookmark) {
    return new Promise((resolve, reject) => {
      const deleteBookmark = () => {
        bookmark.destroy().then(() => {
          this.appEvents.trigger("bookmarks:changed", null, bookmark.attachedTo());
          this._removeBookmarkFromList(bookmark);
          resolve(true);
        }).catch(error => {
          reject(error);
        });
      };
      if (!bookmark.reminder_at) {
        return deleteBookmark();
      }
      this.dialog.deleteConfirm({
        message: I18n.t("bookmarks.confirm_delete"),
        didConfirm: () => deleteBookmark(),
        didCancel: () => resolve(false)
      });
    });
  },
  screenExcerptForExternalLink(event) {
    if (event?.target?.tagName === "A") {
      if (shouldOpenInNewTab(event.target.href)) {
        openLinkInNewTab(event, event.target);
      }
    }
  },
  editBookmark(bookmark) {
    this.modal.show(BookmarkModal, {
      model: {
        bookmark: new BookmarkFormData(bookmark),
        afterSave: savedData => {
          this.appEvents.trigger("bookmarks:changed", savedData, bookmark.attachedTo());
          this.reload();
        },
        afterDelete: () => {
          this.reload();
        }
      }
    });
  },
  clearBookmarkReminder(bookmark) {
    return ajax(`/bookmarks/${bookmark.id}`, {
      type: "PUT",
      data: {
        reminder_at: null
      }
    }).then(() => {
      bookmark.set("reminder_at", null);
    });
  },
  togglePinBookmark(bookmark) {
    bookmark.togglePin().then(this.reload);
  },
  toggleBulkSelect() {
    this.bulkSelectHelper?.toggleBulkSelect();
    this.rerender();
  },
  selectAll() {
    this.bulkSelectHelper.autoAddBookmarksToBulkSelect = true;
    document.querySelectorAll("input.bulk-select:not(:checked)").forEach(el => el.click());
  },
  clearAll() {
    this.bulkSelectHelper.autoAddBookmarksToBulkSelect = false;
    document.querySelectorAll("input.bulk-select:checked").forEach(el => el.click());
  },
  // for the classNameBindings
  get bulkSelectEnabled() {
    return this.bulkSelectHelper?.bulkSelectEnabled;
  },
  _removeBookmarkFromList(bookmark) {
    this.content.removeObject(bookmark);
  },
  _toggleSelection(target, bookmark, isSelectingRange) {
    const selected = this.selected;
    if (target.checked) {
      selected.addObject(bookmark);
      if (isSelectingRange) {
        const bulkSelects = Array.from(document.querySelectorAll("input.bulk-select")),
          from = bulkSelects.indexOf(target),
          to = bulkSelects.findIndex(el => el.id === this.lastChecked.id),
          start = Math.min(from, to),
          end = Math.max(from, to);
        bulkSelects.slice(start, end).filter(el => el.checked !== true).forEach(checkbox => {
          checkbox.click();
        });
      }
      this.set("lastChecked", target);
    } else {
      selected.removeObject(bookmark);
      this.set("lastChecked", null);
    }
  },
  click(e) {
    const onClick = (sel, callback) => {
      let target = e.target.closest(sel);
      if (target) {
        callback(target);
      }
    };
    onClick("input.bulk-select", () => {
      const target = e.target;
      const bookmarkId = target.dataset.id;
      const bookmark = this.content.find(item => item.id.toString() === bookmarkId);
      this._toggleSelection(target, bookmark, this.lastChecked && e.shiftKey);
    });
  }
}, (_applyDecoratedDescriptor(_obj, "removeBookmark", [action], Object.getOwnPropertyDescriptor(_obj, "removeBookmark"), _obj), _applyDecoratedDescriptor(_obj, "screenExcerptForExternalLink", [action], Object.getOwnPropertyDescriptor(_obj, "screenExcerptForExternalLink"), _obj), _applyDecoratedDescriptor(_obj, "editBookmark", [action], Object.getOwnPropertyDescriptor(_obj, "editBookmark"), _obj), _applyDecoratedDescriptor(_obj, "clearBookmarkReminder", [action], Object.getOwnPropertyDescriptor(_obj, "clearBookmarkReminder"), _obj), _applyDecoratedDescriptor(_obj, "togglePinBookmark", [action], Object.getOwnPropertyDescriptor(_obj, "togglePinBookmark"), _obj), _applyDecoratedDescriptor(_obj, "toggleBulkSelect", [action], Object.getOwnPropertyDescriptor(_obj, "toggleBulkSelect"), _obj), _applyDecoratedDescriptor(_obj, "selectAll", [action], Object.getOwnPropertyDescriptor(_obj, "selectAll"), _obj), _applyDecoratedDescriptor(_obj, "clearAll", [action], Object.getOwnPropertyDescriptor(_obj, "clearAll"), _obj), _applyDecoratedDescriptor(_obj, "bulkSelectEnabled", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_obj, "bulkSelectEnabled"), _obj)), _obj)));